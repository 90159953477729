//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'StockPrices',
    data() {
        return {
            item: {},
            goUp: false,
            changePercentage:0,
            isLoading: true,
        }
    },
    mounted() {
        let self = this;
        self.axios
            .get(process.env.VUE_APP_APIEndPoint + 'SharedPrice/GetOne')
            .then(response => {
                self.item = response.data;
                self.goUp = response.data.open < response.data.prevClosePrice;
                self.changePercentage = Math.abs(response.data.changePercentage)
            })
            .finally(
                () => self.isLoading = false
            );
    },
   
};

